import styled from '@emotion/styled';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const ServiceStopModal = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (
      moment().isAfter('2023-07-08 15:30:00') &&
      moment().isBefore('2023-07-08 20:00:59')
    ) {
      setShow(true);
      document.body.style.overflow = 'hidden';

      setTimeout(() => {
        const w: any = window;
        w.ChannelIO('hideChannelButton');
      });
    }
  }, []);

  return show ? (
    <Wrapper>
      <Image src={require('../assets/service-stop.png')} />
    </Wrapper>
  ) : (
    <></>
  );
};

export default ServiceStopModal;

const Image = styled.img`
  max-width: 820px;
  width: 100%;
  border-radius: 20px;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
