import React from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import useSWR from 'swr';
import auth from '../utils/auth';

interface IPrivateRoute extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: IPrivateRoute) => {
  const { data } = useSWR(() => (auth.getToken() ? '/mypage' : ''));
  const history = useHistory();

  if (!auth.getToken() && rest.path !== '/programDetail') {
    history.push('/auth/login', { redirect_to: window.location.href });
    return null;
  }
  if (auth.getToken() && !data && rest.path !== '/programDetail') return null;

  if (rest.path !== '/programDetail' && auth.getType() !== 'coach') {
    if (data?.purchase_check === 0) {
      if (window.localStorage.getItem('redirect')) {
        window.alert('수강권 구매 후 바로 입장 가능합니다.');
        return <Redirect to="/programDetail?id=1" />;
      } else {
        return <Redirect to="/" />;
      }
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.getToken() !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/auth/login`} />
        )
      }
    />
  );
};

export default PrivateRoute;
