import loadable from '@loadable/component';

export const Root = loadable(() => import('./Root'));

//Root Soritune Intro
export const SorituneMessage = loadable(
  () => import('./root/sorituneIntro/Message'),
);
export const SorituneIntro = loadable(
  () => import('./root/sorituneIntro/Intro'),
);
export const SorituneLearning = loadable(
  () => import('./root/sorituneIntro/root'),
);

//Root Class Intro
export const ClassTuningProgram = loadable(
  () => import('./root/classIntro/TuningProgram'),
);
export const ClassCoaching = loadable(
  () => import('./root/classIntro/Coaching'),
);
export const ClassActuality = loadable(
  () => import('./root/classIntro/Actuality'),
);

export const SubMain = loadable(() => import('./subMain/SubMain'));
export const Redirect = loadable(() => import('./PayRedirect'));
export const ProgramDetail = loadable(() => import('./ProgramDetail'));
export const CoachingDetail = loadable(() => import('./CoachingDetail'));
export const AuthLogin = loadable(() => import('./auth/Login'));
export const AuthFindAccount = loadable(() => import('./auth/FindAccount'));
export const AuthFindPassword = loadable(() => import('./auth/FindPassword'));
export const AuthNewPassword = loadable(() => import('./auth/NewPassword'));
export const AuthRegister = loadable(() => import('./auth/Register'));
export const AppDaily = loadable(() => import('./app/Daily'));
export const AppDailyMission = loadable(() => import('./app/DailyMission'));
export const AppChatting = loadable(() => import('./app/Chatting-v2'));
export const AppCurriculum = loadable(() => import('./app/Curriculum'));
export const AppCurriculumPage = loadable(() => import('./app/Curriculum-v2'));
export const AppMindset = loadable(() => import('./app/Mindset'));
export const AppMindsetInfo = loadable(() => import('./app/MindsetInfo'));
export const AppHelpdesk = loadable(() => import('./app/Helpdesk'));
export const AppEvent = loadable(() => import('./app/Event'));
export const AppEventInfo = loadable(() => import('./app/EventInfo'));
export const AppSetting = loadable(() => import('./app/Setting'));
export const AppMypage = loadable(() => import('./app/Mypage'));
export const AppQuestion = loadable(() => import('./app/Question'));
export const AppQuestionInfo = loadable(() => import('./app/QuestionInfo'));
export const AppInquiry = loadable(() => import('./app/Inquiry'));
export const AppNotice = loadable(() => import('./app/Notice'));
export const AppNoticeDetail = loadable(() => import('./app/NoticeDetail'));
export const AppReservation = loadable(() => import('./app/Reservation'));
export const CoachLogin = loadable(() => import('./coach/Login'));
